:root {
  // Global Color
  // src/const.ts BG_COLOR
  --danger: #c71212;
  --invalid: #c71212;
  --primary: #de0029;
  --black: #2e333b;
  --white: #fff;
  --gray: #f5f5f5;
  --dark-gray: #ddd;

  // Layout
  --on-narrow-page-padding: 16px;

  // UI
  --button-normal-bg: #ffffff;
  --button-normal-bg-to: #f4f4f4;
  --button-normal-text: var(--black);
  --button-primary-bg: var(--primary);
  --button-primary-bg-to: #c90025;
  --button-primary-text: var(--white);
  --button-danger-bg: var(--danger);
  --button-danger-text: var(--white);
  --drop-shadow: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.16));
  --border: #cccccc;
  --footer-bg: #393939;
  --footer-text: var(--white);
  --header-bg: var(--white);
  --input-bg: #ffffff;
  --input-border: #d8d8d8;
  --input-outline: var(--dark-gray);
  --legend-separator: #c9c9c9;
  --menu-bg: var(--white);
  --menu-separator: #ebebeb;
  --modal-bg: var(--white);
  --transition: 0.5s cubic-bezier(0, 1.1, 0.1, 1.02);
  --description: #606060;
  --message-bg: #fff5f5;
}
