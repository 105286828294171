.root {
  display: grid;
  justify-content: center;
  gap: 80px 20px;
}

.title {
  display: block;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.description {
  grid-row: 1;
  grid-column: 1/3;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.cancel {
  grid-row: 2.5;
  grid-column: 1;
}

.submit {
  grid-row: 2.5;
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

a.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: var(--button-normal-text);
  width: 190px;
  min-height: 40px;
  cursor: pointer;
  background: linear-gradient(
    var(--button-normal-bg),
    var(--button-normal-bg-to)
  );
  transition: var(--transition);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  &:hover,
  &:active {
    transform: scale(0.98);
  }

  &--primary {
    background: linear-gradient(
      var(--button-primary-bg),
      var(--button-primary-bg-to)
    );
    color: var(--button-primary-text);
  }
}

.okbotton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.close {
  position: absolute;
  background: transparent;
  border: none;
  right: 5px;
  top: 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 0;
  }
}

.cancel,
.submit {
  @media screen and (max-width: 640px) {
    max-width: 90px;
  }
}
