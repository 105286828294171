.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--footer-bg);
  color: var(--footer-text);
}

.copyright {
  font-size: 12px;
}
