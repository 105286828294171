.root {
  display: grid;
  grid-template:
    'links links links' auto
    '. . .' 1fr
    '. version .' auto
    '. . .' 20px/
    20px 1fr 20px;
  background: var(--menu-bg);
  border-radius: 0 0 6px 6px;

  ul {
    grid-area: links;
    width: inherit;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    border-bottom: 1px solid var(--menu-separator);
    a {
      display: grid;
      grid-template:
        '. . . ' 10px
        '. title .' 30px
        '. . . ' 10px /
        16px 1fr 16px;
      align-items: center;
      text-decoration: none;
      color: var(--black);

      &:hover {
        opacity: 0.8;
      }

      &:visited {
        color: inherit;
      }

      img {
        grid-area: icon;
        object-fit: cover;
      }

      span {
        grid-area: title;
        display: block;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.version {
  grid-area: version;
  color: #a5a5a5;
  display: block;
  text-align: right;
  font-style: 10px;
  margin-top: 20px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0;
  width: 600px;
  height: min-content;
  background-color: white;
  border-radius: 6px;
  outline: none;

  @media screen and (max-width: 640px) {
    width: calc(100% - 2 * var(--on-narrow-page-padding));
    margin: max(140px, 20vh) var(--on-narrow-page-padding) 0;
    padding-bottom: 50px;
  }

  .header {
    padding: 24px 100px 24px;
    border-bottom: 1px solid rgb(237, 237, 237);
    @media screen and (max-width: 640px) {
      padding: 24px var(--on-narrow-page-padding) 24px;
    }
  }

  .title {
    display: block;
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
  }

  .body {
    justify-content: center;
    padding: 40px 100px 50px;
    @media screen and (max-width: 640px) {
      padding: 40px var(--on-narrow-page-padding) 0;
    }
  }

  .close {
    position: absolute;
    background: transparent;
    border: none;
    right: 32px;
    top: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    @media screen and (max-width: 640px) {
      right: 20px;
    }
    img {
      margin: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.show {
  display: 'flex';
  align-items: 'center';
  justify-content: 'right';
  text-decoration: 'none';
  width: 100%;
  height: 49px;
  padding-left: 16px;

  cursor: pointer;
  border: none;
  background: none;
  color: black;
  box-shadow: none;
  text-align: left;

  &:hover {
    opacity: 0.8;
    transform: scale(1);
  }
}
