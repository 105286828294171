.root {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-template:
    'header header header' 80px
    '.        .      .   ' 60px
    '.      main     .   ' auto
    '.        .      .   ' minmax(100px, 1fr)
    'footer footer footer' 54px /
    minmax(var(--on-narrow-page-padding), 1fr) minmax(0, 600px) minmax(var(--on-narrow-page-padding), 1fr);

  &--wide {
    grid-template:
      'header header header' 80px
      '.        .      .   ' 60px
      '.      main     .   ' auto
      '.        .      .   ' minmax(100px, 1fr)
      'footer footer footer' 54px /
      minmax(var(--on-narrow-page-padding), 1fr) minmax(0, 710px) minmax(var(--on-narrow-page-padding), 1fr);
  }

  @media screen and (max-width: 640px) {
    grid-template:
      'header header header' 80px
      '.        .      .   ' 60px
      '.      main     .   ' auto
      '.        .      .   ' minmax(100px, 1fr)
      'footer footer footer' 54px /
      var(--on-narrow-page-padding) 1fr var(--on-narrow-page-padding);
  }
}

.header {
  grid-area: header;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
  z-index: 3;
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;
}

.menu {
  position: absolute;
  z-index: 2;
  top: 80px;
  right: 10px;
  width: 300px;
  transform: translateY(-100%);
  transition: var(--transition);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  margin-top: -10px;
  padding-top: 10px;

  &__show {
    transform: translateY(0%);
  }
}

.mask {
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px - 54px);
  transition: var(--transition);
  opacity: 0;
  pointer-events: none;
  background-color: transparent;
  &__modal {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__show {
    opacity: 1;
    pointer-events: auto;
  }
}
