.root {
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: var(--button-normal-text);
  width: 190px;
  min-height: 40px;
  cursor: pointer;
  background: linear-gradient(var(--button-normal-bg), var(--button-normal-bg-to));
  transition: var(--transition);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  &:hover,
  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    opacity: 0.3;
  }

  &--primary {
    background: linear-gradient(var(--button-primary-bg), var(--button-primary-bg-to));
    color: var(--button-primary-text);
  }

  &--danger {
    background: var(--button-danger-bg);
    color: var(--button-danger-text);
  }
}
