@import '../../../node_modules/modern-css-reset/dist/reset.min.css';
@import './variables';

* {
  box-sizing: border-box;
}

html,
body {
  color: var(--black);
  font-size: 12px;
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: hidden;
}

a {
  color: var(--primary);
}
