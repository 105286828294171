.root {
  position: relative;
  display: grid;
  align-items: center;
  grid-template:
    '.     .   .   .    .   .   .' 1fr
    '.   logo  .   .    .  menu .' 44px
    '.     .   .   .    .   .   .' 1fr /
    28px auto 1fr auto 1fr 44px 28px;

  background: var(--header-bg);
  border-bottom: 1px solid var(--primary);

  @media screen and (max-width: 640px) {
    grid-template:
      '.     .   .   .    .   .   .' 1fr
      '.   logo  .   .    .  menu .' 44px
      '.     .   .   .    .   .   .' 1fr /
      var(--on-narrow-page-padding) auto 1fr auto 1fr 44px var(--on-narrow-page-padding);
  }
}

.logo {
  z-index: 1;
  grid-area: logo;
}

.img {
  &__sp {
    height: 50px;
    display: none;
    @media screen and (max-width: 640px) {
      display: block;
    }
  }
  &__pc {
    height: 44px;
    display: block;
    @media screen and (max-width: 640px) {
      display: none;
    }
  }
}

.name {
  z-index: 0;
  grid-row: 1/4;
  grid-column: 1/8;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-left: 20px;
}

.menu {
  z-index: 1;
  grid-area: menu;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  &__selected {
    background: rgb(242, 242, 242);
  }

  img {
    width: 100%;
    height: 100%;
  }
}
